* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.hover {
  margin: 0px auto;
  margin-top: 8px;

  border-radius: "50px";
}

.hover:hover {
  cursor: pointer;
}

.App {
  height: 100vh;
  background: #ececec;
}
.container_card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.App2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  height: 450px;
  margin: 10px;
}
.site-card-border-less-wrapper {
  max-width: 500px;
  margin: 0;
}

.form2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;

  margin: 10px;
}

.login-form-forgot {
  float: right;
  bottom: 0;
}

.ant-col-rtl .login-form-forgot {
  float: center;
}

.login-form-button {
  width: 100%;
  height: 1.5 rem;
}
.login-form > label {
  font-size: 15px;
  margin-bottom: 15px;
}

h4 {
  font-size: 2.28rem;
  line-height: 110%;
}

.ant-modal-body > div {
  margin: 5px !important;
}
.login__logo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/*dashboard*/

.add {
  position: absolute;
  bottom: 20px;
  right: 40px;
}

.add:hover {
  cursor: pointer;
}

.upload {
  position: absolute;
  bottom: 40px;
  right: 50px;
}

.upload:hover {
  cursor: pointer;
}

.logo {
  height: 44px;
  margin-left: 1rem;
}

/*Nabvar*/
.navbar {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.other {
  display: flex;
  flex-direction: row;
}

.item1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: calc(100vh - 77px) !important;
}

.butonDrag {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.content {
  overflow: auto !important;
  bottom: 0px !important;
  height: calc(100vh - 77px) !important;
  margin-left: 200px !important;
  margin-top: 77px;
}
/*steps*/

.content_web {
  margin-left: 0px !important;
}

.steps-content {
  min-height: 200px;
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
}

.step {
  display: none !important;
}

.steps-action {
  margin-top: 24px;
}

.pdfWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.form_user {
  width: 50%;
}

@media (min-width: 992px) {
  .menu {
    display: none !important;
  }
  .menu__2 {
    margin-top: 5.19px !important;
    height: 32px !important;
  }
  .Card_Loggin {
    width: 500px !important;
  }
  .list_correos {
    width: 400px !important;
  }
}

@media (max-width: 992px) {
  .logo {
    height: 37px;
  }
  .list_correos {
    width: 280px !important;
  }
  .menu__2 {
    margin-top: 1.5px !important;
    height: 33px !important;
  }
  .content {
    margin-left: 0px !important;
  }
  .form_user {
    width: 100%;
  }
}
